// @use "../node_modules/bootstrap/scss/vendor/_rfs.scss";
@import "./../../sass/variables.scss";
@import "./../../components/FontOptions/fontOptions.scss";
@import "~bootstrap/scss/bootstrap.scss";

.App {
  text-align: center;
  color: $darkBlue;
}

.splash-row {
  height: 100vh;
  background-size: cover;
  background-position: center;

  h2 {
    margin-top: $alt-section-padding;
    color: $white;
    font-style: italic;
    font-weight: 300;
    @include font-resize(1.75rem);
  }

  .simplified-view & {
    height: auto;
  }
}

a.skip-link {
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  opacity: 0;
  transition: opacity 0.25s;
  transition-delay: 1.5s;

  &:focus {
    opacity: 1;
    transition-delay: 0s;
  }
}


.about-row {
  @include padding(2.5rem 0);

  p {
    @include margin(2.5rem 0);
    color: $darkBlue;
    @include font-resize($about-font-size);
    font-style: italic;

    .simplified-view & {
      margin-bottom: 0;
    }
  }

  .simplified-view & {
    margin-bottom: 0;
  }
}

.purpose-row {
  background-color: $yellow;
  @include padding($main-section-padding 0);

  .purpose-text {
    text-align: left;
  }

  .purpose-picture {
    max-width: 100%;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
  }
}
