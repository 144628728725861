$font-sizes: (
  "0-5": 0.5,
  "0-6": 0.6,
  "0-7": 0.7,
  "0-8": 0.8,
  "0-9": 0.9,
  "1-0": 1,
  "1-1": 1.1,
  "1-2": 1.2,
  "1-3": 1.3,
  "1-4": 1.4,
  "1-5": 1.5,
  "1-6": 1.6,
  "1-7": 1.7,
  "1-8": 1.8,
  "1-9": 1.9,
  "2-0": 2,
  "2-1": 2.1,
  "2-2": 2.2,
  "2-3": 2.3,
  "2-4": 2.4,
  "2-5": 2.5,
  "2-6": 2.6,
  "2-7": 2.7,
  "2-8": 2.8,
  "2-9": 2.9,
  "3-0": 3,
  "3-1": 3.1,
  "3-2": 3.2,
  "3-3": 3.3,
  "3-4": 3.4,
  "3-5": 3.5,
  "3-6": 3.6,
  "3-7": 3.7,
  "3-8": 3.8,
  "3-9": 3.9,
  "4-0": 4,
  "4-1": 4.1,
  "4-2": 4.2,
  "4-3": 4.3,
  "4-4": 4.4,
  "4-5": 4.5,
  "4-6": 4.6,
  "4-7": 4.7,
  "4-8": 4.8,
  "4-9": 4.9,
  "5-0": 5,
);

@mixin font-resize($size) {
  font-size: $size;
  @each $key, $value in $font-sizes {
    body.font-size-#{$key} & {
      font-size: $value * $size;
    }
  }
}
