@import "src/sass/variables.scss";
@import "./../FontOptions/fontOptions.scss";

.a11y-container {
  display: flex;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 5;

  .a11y__settings-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    min-width: 36px;

    .a11y__settings-toggle,
    .a11y__settings-close {
      background-color: transparent;
      text-align: center;
      color: white;
      border: none;
      outline: none;
      min-height: 36px;
      min-width: 36px;
      @include hc-text-shadow;

      &:hover,
      &:focus-visible {
        box-shadow: $hc-focus-box-shadow;
      }
    }

    menu.a11y__settings {
      display: flex;
      margin: 0;
      padding: 0 0.75rem;
      border: solid white;
      border-width: 0 1px;


      li {
        list-style: none;
      }
    }
  }

  .selector-container {
    display: flex;

    .language-flag {
      position: relative;
      left: 1.67rem;
      font-size: 1.325rem;
      line-height: 2.25rem;
      opacity: 0.9;
    }
  
    .language-select {
      background-color: transparent;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+CiAgPHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPSdibGFjaycgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBzdHJva2Utd2lkdGg9JzUnIGQ9J00yIDVsNiA2IDYtNicvPgogIDxwYXRoIGZpbGw9J25vbmUnIHN0cm9rZT0nd2hpdGUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgc3Ryb2tlLXdpZHRoPScyJyBkPSdNMiA1bDYgNiA2LTYnLz4KPC9zdmc+);
      border: none;
      outline: none;
      box-shadow: $no-box-shadow;
      cursor: pointer;
      @include hc-text-shadow;
  
      &:hover,
      &:focus-visible {
        box-shadow: $hc-focus-box-shadow;
      }
    }

    &.hasFlags {
      .language-select {
        padding-left: 2rem;
      }
    }
  }

  .font-options-container,
  .simplified-view-container {
    text-align: center;
    position: relative;
    margin-right: .5rem;

    .font-options-toggle,
    .simplified-view-toggle {
      color: white;
      background-color: transparent;
      border: none;
      box-shadow: $no-box-shadow;
      outline: none;
      min-height: 36px;
      min-width: 36px;
      cursor: pointer;
      @include hc-text-shadow;
  
      &.isOpen {
        border: $unfocus-border;
      }

      &:hover,
      &:focus-visible {
        box-shadow: $hc-focus-box-shadow;

        &.isOpen {
          border: none;
        }
      }
    }

    form.font-options {
      position: absolute;
      right: 0;
      top: calc(36px + 0.5rem);
      min-width: 250px;
      padding: 1rem;
      background-color: white;
      border: 1px solid black;

      .font-family-monospace & {
        min-width: 280px;
      }

      legend {
        @include font-resize(1.25rem);
        font-weight: 600;
        margin-bottom: 0.5rem;
        padding-bottom: 0.25rem;
        border-bottom: 1px solid black;
      }

      input[type="range"] {
        width: 100%;
      }

      .font-options__row {
        margin-bottom: 0.25rem;
      }
    }
  }
}
